@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    color-scheme: dark;

    --gx-accent-l-8: 347deg 96% 4.4%;
    --gx-accent-l-16: 347deg 96% 8.8%;
    --gx-accent-l-24: 347deg 96% 13.2%;
    --gx-accent-l-30: 347deg 96% 16.5%;
    --gx-accent-l-60: 347deg 96% 33%;
    --gx-accent-l-80: 347deg 96% 44%;
    --gx-accent-l-100: 347deg 96% 55%;
    --gx-accent-l-120: 347deg 96% 66%;
    --gx-accent-l-140: 347deg 96% 77%;
    --gx-accent-l-160: 347deg 96% 88%;
    --gx-gray-10: 0deg 0% 10%;
    --gx-gray-16: 0deg 0% 16%;
    --gx-gray-20: 0deg 0% 20%;
    --gx-gray-30: 0deg 0% 30%;
    --gx-gray-40: 0deg 0% 40%;
    --gx-gray-50: 0deg 0% 50%;
    --gx-gray-60: 0deg 0% 60%;
    --gx-gray-70: 0deg 0% 70%;
    --gx-gray-80: 0deg 0% 80%;
    --gx-gray-90: 0deg 0% 90%;
    --gx-no-00: 258deg 24% 0%;
    --gx-no-04: 258deg 24% 4%;
    --gx-no-08: 258deg 24% 8%;
    --gx-no-12: 258deg 24% 12%;
    --gx-no-16: 258deg 24% 16%;
    --gx-no-20: 258deg 24% 20%;
    --gx-no-24: 258deg 24% 24%;
    --gx-no-32: 258deg 24% 32%;
    --gx-no-40: 258deg 24% 40%;
    --gx-no-59: 258deg 24% 59%;
    --gx-no-77: 258deg 24% 77%;
    --gx-no-80: 258deg 24% 80%;
    --gx-no-88: 258deg 24% 88%;
    --gx-no-90: 258deg 24% 90%;
    --gx-no-92: 258deg 24% 92%;
    --gx-no-96: 258deg 24% 96%;
    --gx-no-98: 258deg 24% 98%;
    --gx-no-100: 258deg 24% 100%;
    --gx-warning-red-l-100: 0deg 100% 50%;
    --gx-warning-red-l-120: 0deg 100% 60%;
    --gx-warning-red-l-140: 0deg 100% 70%;
    --gx-warning-yellow-l-100: 47.88deg 100% 60%;
    --gx-warning-yellow-l-120: 47.88deg 100% 72%;
    --gx-warning-yellow-l-140: 47.88deg 100% 84%;
    --gx-accent-100-contrast: 0 0 100%;
  }
}
